import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'normalize.css';
import './index.stylus';

const app = createApp(App);
app.config.errorHandler = (err) => {
  console.error(err);
};
app.use(router).mount('#app');
