import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/noise-color',
    name: 'NoiseColor',
    component: () => import(/* webpackChunkName: "noise-color" */ '../views/NoiseColor.vue'),
  },
  {
    path: '/curved-panel',
    name: 'CurvedPanel',
    component: () => import(/* webpackChunkName: "curved-panel" */ '../views/CurvedPanel.vue'),
  },
  {
    path: '/stars',
    name: 'Stars',
    component: () => import(/* webpackChunkName: "stars" */ '../views/Stars.vue'),
  },
  {
    path: '/particle-star',
    name: 'ParticleStar',
    component: () => import(/* webpackChunkName: "particle-star" */ '../views/ParticleStar.vue'),
  },
  {
    path: '/particle-text',
    name: 'Particle-text',
    component: () => import(/* webpackChunkName: "particle-text" */ '../views/ParticleText.vue'),
  },
  {
    path: '/particle-text1',
    name: 'Particle-text1',
    component: () => import(/* webpackChunkName: "particle-text" */ '../views/ParticleText1.vue'),
  },
  {
    path: '/model-show',
    name: 'ModelShow',
    component: () => import(/* webpackChunkName: "model-show" */ '../views/ModelShow.vue'),
  },
  {
    path: '/particle-cylinder',
    name: 'ParticleCylinder',
    component: () =>
      import(/* webpackChunkName: "particle-cylinder" */ '../views/ParticleCylinder.vue'),
  },
  {
    path: '/particle-5g',
    name: 'Particle5G',
    component: () => import(/* webpackChunkName: "particle-5g" */ '../views/Particle5G.vue'),
  },
  {
    path: '/particle-5g/renew',
    name: 'ParticleRenew',
    component: () => import(/* webpackChunkName: "particle-5g" */ '../views/Particle5G/renew.vue'),
  },
  {
    path: '/particle-5g/speed',
    name: 'ParticleSpeed',
    component: () => import(/* webpackChunkName: "particle-5g" */ '../views/Particle5G/speed.vue'),
  },
  {
    path: '/particle-5g/technology',
    name: 'ParticleTechnology',
    component: () =>
      import(/* webpackChunkName: "particle-5g" */ '../views/Particle5G/technology.vue'),
  },
  {
    path: '/particle-spiral',
    name: 'ParticleSpiral',
    component: () => import(/* webpackChunkName: "particle-5g" */ '../views/ParticleSpiral.vue'),
  },
  {
    path: '/particle-curve',
    name: 'ParticleCurve',
    component: () => import(/* webpackChunkName: "particle-5g" */ '../views/ParticleCurve.vue'),
  },
  {
    path: '/ar-text',
    name: 'ARText',
    component: () => import(/* webpackChunkName: "ar-text" */ '../views/ARText.vue'),
  },
  {
    path: '/particle-shower',
    name: 'ParticleShower',
    component: () =>
      import(/* webpackChunkName: "particle-shower" */ '../views/ParticleShower.vue'),
  },
  {
    path: '/particle-sky',
    name: 'ParticleSky',
    component: () => import(/* webpackChunkName: "particle-sky" */ '../views/ParticleSky.vue'),
  },
  {
    path: '/particle-bg',
    name: 'ParticleBg',
    component: () => import(/* webpackChunkName: "particle-sky" */ '../views/ParticleBg.vue'),
  },
  {
    path: '/wave-text',
    name: 'WaveText',
    component: () => import(/* webpackChunkName: "particle-sky" */ '../views/WaveText.vue'),
  },
  {
    path: '/bubble',
    name: 'Bubble',
    component: () => import(/* webpackChunkName: "bubble" */ '../views/Bubble.vue'),
  },
  {
    path: '/bubble2',
    name: 'Bubble2',
    component: () => import(/* webpackChunkName: "bubble2" */ '../views/Bubble2.vue'),
  },
  {
    path: '/bump_physics',
    name: 'BumpPhysics',
    component: () => import(/* webpackChunkName: "bump-physics" */ '../views/BumpPhysics.vue'),
  },
  {
    path: '/lines',
    name: 'Lines',
    component: () => import(/* webpackChunkName: "lines" */ '../views/Lines.vue'),
  },
  {
    path: '/sand-text',
    name: 'SandText',
    component: () => import(/* webpackChunkName: "sand-text" */ '../views/SandText.vue'),
  },
  {
    path: '/sand-text2',
    name: 'SandText2',
    component: () => import(/* webpackChunkName: "sand-text" */ '../views/SandText.vue'),
  },
  {
    path: '/fire',
    name: 'Fire',
    component: () => import(/* webpackChunkName: "fire" */ '../views/Fire.vue'),
  },
  {
    path: '/image-effect/ball',
    name: 'Ball',
    component: () => import(/* webpackChunkName: "ball" */ '../views/ImageEffect/Ball.vue'),
  },
  {
    path: '/image-effect/bump',
    name: 'Bump',
    component: () => import(/* webpackChunkName: "bump" */ '../views/ImageEffect/Bump.vue'),
  },
  {
    path: '/image-effect/diced',
    name: 'Diced',
    component: () => import(/* webpackChunkName: "diced" */ '../views/ImageEffect/Diced.vue'),
  },
  {
    path: '/image-effect/combine',
    name: 'Combine',
    component: () => import(/* webpackChunkName: "combine" */ '../views/ImageEffect/Combine.vue'),
  },
  {
    path: '/image-effect/cursor-bump',
    name: 'CursorBump',
    component: () =>
      import(/* webpackChunkName: "cursor-bump" */ '../views/ImageEffect/CursorBump.vue'),
  },
  {
    path: '/image-effect/grid',
    name: 'Grid',
    component: () => import(/* webpackChunkName: "cursor-bump" */ '../views/ImageEffect/Grid.vue'),
  },
  {
    path: '/image-effect/deformation',
    name: 'Deformation',
    component: () =>
      import(/* webpackChunkName: "cursor-bump" */ '../views/ImageEffect/Deformation.vue'),
  },
  {
    path: '/image-effect/wave',
    name: 'Wave',
    component: () => import(/* webpackChunkName: "cursor-bump" */ '../views/ImageEffect/Wave.vue'),
  },
  {
    path: '/vooc/line',
    name: 'Line',
    component: () => import(/* webpackChunkName: "Line" */ '../views/Vooc/Line.vue'),
  },
  {
    path: '/vooc/tailing',
    name: 'Tailing',
    component: () => import(/* webpackChunkName: "Tailing" */ '../views/Vooc/Tailing.vue'),
  },
  {
    path: '/vooc/lighten',
    name: 'Lighten',
    component: () => import(/* webpackChunkName: "Lighten" */ '../views/Vooc/Lighten.vue'),
  },
  {
    path: '/shine',
    name: 'Shine',
    component: () => import(/* webpackChunkName: "Shine" */ '../views/Shine.vue'),
  },
  {
    path: '/stairs',
    name: 'stairs',
    component: () => import(/* webpackChunkName: "stairs" */ '../views/3dStairs.vue'),
  },
  {
    path: '/Exhibition',
    name: 'Exhibition',
    component: () => import(/* webpackChunkName: "Exhibition" */ '../views/Exhibition/index.vue'),
  },
  {
    path: '/exhibition-other',
    name: 'Exhibition-other',
    component: () =>
      import(/* webpackChunkName: "Exhibition" */ '../views/ExhibitionOld/index.vue'),
  },
  {
    path: '/Phone',
    name: 'Phone',
    component: () => import(/* webpackChunkName: "Phone" */ '../views/Exhibition/phone.vue'),
  },
  {
    path: '/handtrack-phone',
    component: () =>
      import(/* webpackChunkName: "handtrack-phone" */ '../views/Handtrack/phone.vue'),
  },
  {
    path: '/handtrack-text',
    component: () => import(/* webpackChunkName: "handtrack-text" */ '../views/Handtrack/text.vue'),
  },
  {
    path: '/safety-shield',
    component: () =>
      import(/* webpackChunkName: "safety-shield" */ '../views/SafetyCenter/Shield/index.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
