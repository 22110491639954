<template>
  <div id="nav" v-show="indexVisible">
    <router-link to="/bubble">动效演示：水波球</router-link>
    <router-link to="/bubble2">动效演示：水波球 2</router-link>
    <router-link to="/bump_physics">物理模拟：弹弹球 </router-link>
    <router-link to="/particle-text">粒子动效：文字</router-link>
    <router-link to="/particle-text1">粒子动效：文字加星河背景</router-link>
    <router-link to="/particle-shower">粒子动效：“星钻雨”</router-link>
    <router-link to="/particle-sky">粒子动效：星河</router-link>
    <router-link to="/particle-bg">粒子动效：星空</router-link>
    <router-link to="/particle-5g">粒子动效：5G</router-link>
    <router-link to="/particle-5g/renew">粒子动效：5G 更新</router-link>
    <router-link to="/particle-5g/speed">粒子动效：5G 速度</router-link>
    <router-link to="/particle-5g/technology">粒子动效：5G 技术</router-link>
    <router-link to="/particle-cylinder">粒子动效：圆柱面</router-link>
    <router-link to="/particle-spiral">粒子动效：螺旋</router-link>
    <router-link to="/particle-curve">粒子动效：曲线</router-link>
    <router-link to="/ar-text">粒子动效：AR 文字效果</router-link>
    <router-link to="/model-show">模型：3D 模型展示</router-link>
    <router-link to="/stars">“流星”拖尾隧道：instances 使用</router-link>
    <router-link to="/wave-text">波浪文字</router-link>
    <router-link to="/particle-star">贴图：粒子贴图</router-link>
    <router-link to="/curved-panel">贴图：柱面贴图</router-link>
    <router-link to="/noise-color">示例：颜色</router-link>
    <router-link to="/lines">线条动效</router-link>
    <router-link to="/sand-text">沙字</router-link>
    <router-link to="/sand-text2">沙字2</router-link>
    <router-link to="/fire">fire</router-link>
    <router-link to="/image-effect/ball">图片效果 - 球体</router-link>
    <!-- <router-link to="/image-effect/bump">图片效果 - 凹凸</router-link>
    <router-link to="/image-effect/diced">图片效果 - 分割</router-link> -->
    <router-link to="/image-effect/combine">图片效果 - 凹凸 + 分割</router-link>
    <router-link to="/image-effect/cursor-bump">图片效果 - 凹凸跟随</router-link>
    <router-link to="/image-effect/deformation">图片效果 - 扭曲</router-link>
    <router-link to="/image-effect/grid">图片效果 - 网格</router-link>
    <router-link to="/image-effect/wave">视频效果 - 波</router-link>
    <router-link to="/vooc/line">VOOC - 线条</router-link>
    <router-link to="/vooc/tailing">VOOC - 拖尾</router-link>
    <router-link to="/vooc/lighten">VOOC - 照亮</router-link>
    <router-link to="/shine">闪</router-link>
    <router-link to="/stairs">3D楼梯</router-link>
    <router-link to="/exhibition">3D 展馆 - 1</router-link>
    <router-link to="/exhibition-other">3D 展馆 - 2</router-link>
    <router-link to="/phone">3D 手机</router-link>
    <router-link to="/handtrack-phone">手势识别 - 手机</router-link>
    <router-link to="/handtrack-text">手势识别 - 粒子</router-link>
    <router-link to="/safety-shield">安全中心 - 盾牌</router-link>
  </div>
  <router-view />
</template>

<script>
import Vue, { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'App',
  setup() {
    const route = useRoute();
    const indexVisible = computed(() => route.path === '/');
    return { indexVisible };
  },
};
</script>

<style>
#app {
  font-family: OPPOSans, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 0;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<style lang="stylus">
.relative
  position relative

.absolute
  position absolute
  top 0
  left 0

.fixed
  position absolute
  top 0
  left 0

.full,
.full-w
  width 100%

.full,
.full-h
  height 100%
</style>
